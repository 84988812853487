import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService, TranslateStore } from "@ngx-translate/core";
import { environment } from 'src/environments/environment';
import { MatomoModule, MatomoRouterModule } from 'ngx-matomo-client';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { LoadingInterceptor } from './core/interceptors/loading.interceptor';
import { ResponseInterceptor } from './core/interceptors/response.interceptor';
import { XSelectedClientHint } from './core/interceptors/x-selectedClientHint.interceptor';
import { routes } from './app-routing.module';
import { MsalGuard } from '@azure/msal-angular';
import { MessageService } from 'primeng/api';
import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';
import { ModuleGuard } from './core/guards/module.guard';
import { AuthModule } from './core/auth/auth.module';


export const appConfig: ApplicationConfig = {

  providers: [


    provideRouter(routes),
    TranslateService,
    TranslateStore,
    AuthGuard,
    RoleGuard,
    ModuleGuard,
    MsalGuard,
    MessageService,
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true
    },
    provideAnimations(),
    importProvidersFrom(TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }), MatomoModule.forRoot(
      {
        disabled: environment.tracking.disabled,
        trackerUrl: environment.tracking.trackerUrl,
        scriptUrl: environment.tracking.scriptUrl,
        siteId: environment.tracking.siteId,
        acceptDoNotTrack: environment.tracking.acceptDoNotTrack,
        enableLinkTracking: environment.tracking.enableLinkTracking
      }),
      MatomoRouterModule.forRoot({
        exclude: [],
      }),
      AuthModule,
    ),

    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XSelectedClientHint,
      multi: true
    },

  ]
}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/api/lang/', '.json')
}
export function appInitializerFactory(translate: TranslateService) {
  return () => {

    let language = localStorage.getItem('language');

    if (!language) {
      language = 'en';
      localStorage.setItem('language', language);
    }

    translate.setDefaultLang(language);
    return translate.use(language).toPromise();
  };
}

export const provideTranslation = () => ({
  defaultLanguage: 'en',
  loader: {
    provide: TranslateLoader,
    useFactory: httpTranslateLoader,
    deps: [HttpClient, Router]
  }
})
