<app-title [title]="title | uppercase" [text]="description" [darkMode]="false" [big]="true">
  <div class="pt-5">
    <br />
    <span>{{ 'pages.unauthorized.error' | translate}}
    </span>
  </div>
</app-title>

<div class="container container--xl mt-3 mb-3">

  <div class="row">


    <span>
      {{ 'pages.unauthorized.redirect' | translate : { countdown: countdown } }}</span>
  </div>
  <div class="row pt-5 pb-5">

    <h5 class="mt-5 mb-4"> {{ 'pages.unauthorized.contact' | translate }}</h5>

  </div>

  <app-contact>
    <app-contact-card name="Julia Bauch" position="Digital Product Manager" image="assets/img/contact/julia_bauch.jpg"
      email="julia.bauch@arvato-scs.com"></app-contact-card>
    <app-contact-card name="Bhagyashri Khole" position="Digital Product Manager"
      image="assets/img/contact/bhagyashri_khole.jpg" email="bhagyashri.khole@arvato-scs.com"></app-contact-card>
  </app-contact>
</div>