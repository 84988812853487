import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError as _throw } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { NotificationService } from "../notification/notification.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    public notificationService: NotificationService,
    private router: Router,
    private translateService: TranslateService
  ) {
  }

  /**
   * Intercept error request
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(err => {

        if (req.headers.get("x-skip-all-interceptor-handling")) {
          return next.handle(req);
        }

        const error = err.error.errorCode || 'F0408';
        const traceId = err.headers.get('x-trace-id') || '';

        let errorMessageTargetKey: string | undefined;


        if (err.status >= 500) {
          this.router.navigate(['error', error, traceId]);
          return next.handle(req);
        }
        if (err.status === 403) {
          this.router.navigate(['error', error]);
          return next.handle(req);
        }
        if (err.status === 429) {
          this.router.navigate(['error', error]);
          return next.handle(req);
        }

        if (req.headers.get("x-skip-interceptor-handling")) {
          return next.handle(req);
        }

        if (req.headers.get("x-error-message-target-key")) {
          errorMessageTargetKey = req.headers.get("x-error-message-target-key")!
        }

        this.notificationService.showError(
          this.translateService.instant('errors.' + error + '.title') || 'Error',
          this.translateService.instant('errors.' + error + '.description') || 'Something went wrong.',
          errorMessageTargetKey
        );

        return _throw(err);
      })
    );
  }
}
