import { Injectable } from '@angular/core';
import { ClientSettings } from '../store/ClientSettings';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private httpClient: HttpClient) {
  }

  /**
   * Get the current Client
   * @returns The current Client or 'Unknown'
   */
  getClients(ignoreHeader: boolean = false): Observable<ClientSettings[]> {
    if (ignoreHeader) {
      const headers = new HttpHeaders({
        'x-skip-interceptor-handling': '',
      });
      const requestOptions = { headers: headers };

      return this.httpClient
        .get<any>(environment.url.api + 'auth/Clients', requestOptions);
    }

    return this.httpClient
      .get<any>(environment.url.api + 'auth/Clients');
  }

  /**
   * Get client for localstorage
   */
  getClientFromLocalStorage(): ClientSettings | undefined {
    try {
      return <ClientSettings>JSON.parse(<string>localStorage.getItem(environment.localStorage.client)) || undefined
    }
    catch (e) {
      return undefined;
    }
  }

  /**
   * Get the client settings from the session storage
   * @returns ClientSettings
   */
  getClientFromSessionStorage(): ClientSettings | undefined {
    try {
      return <ClientSettings>JSON.parse(<string>sessionStorage.getItem(environment.localStorage.client)) || undefined
    }
    catch (e) {
      return undefined;
    }
  }


  /**
   * Gets the clientSettings from the session storage. If no client has been set, it will take the client from the local storage
   * @returns ClientSettings
   */
  getClientSettingsFromStorage(): ClientSettings | undefined {

    let client = this.getClientFromSessionStorage();

    if (client) {
      return client;
    }

    client = this.getClientFromLocalStorage();

    if (client) {
      sessionStorage.setItem(environment.localStorage.client, JSON.stringify(client));
    }

    return client;
  }


  /**
   * Removes the client information from local and session storage.
   */
  dropClientFromStorage() {
    localStorage.removeItem(environment.localStorage.client)
    sessionStorage.removeItem(environment.localStorage.client)
    sessionStorage.removeItem('brand')
  }

}