import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from "rxjs/operators";
import { ClientService } from "../auth/client.service";

@Injectable()
export class XSelectedClientHint implements HttpInterceptor {

  constructor(private clientService: ClientService) { }

  /**
   * Add X-SelectedClientHint header to requests
   * @param request
   * @param next
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const identifier = this.clientService.getClientSettingsFromStorage()?.client ?? undefined

    if (
      !request.url.includes('Cxc/Clients')
    ) {
      request = request.clone({
        setHeaders: {
          'X-SelectedClientHint': `${identifier}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error) => {
        throw error;
      })
    );
  }
}