import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { AuthStoreService } from 'src/app/core/store/auth.store';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateTextPipe } from '../../../../shared/translation/translation.module';
import { ButtonComponent } from '../../../../shared/button/buton.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [NgClass, ButtonComponent, TranslateTextPipe, TranslateModule]
})
export class LoginComponent {

  isLoggedIn: boolean = false
  backgoundImageClass = "bg-2"

  constructor(
    private authStoreService: AuthStoreService,
    public authService: AuthService,
    private router: Router
  ) { }
  
  ngOnInit(): void {
      this.backgoundImageClass = 'bg-' + Math.floor(Math.random() * 6)
  }
}