import { Injectable, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from "../../../environments/environment";
import { ClientSettings } from './ClientSettings';
import { AuthState } from './AuthState';

@Injectable({ providedIn: 'root' })
export class AuthStoreService {

  private readonly _authSateSource = new BehaviorSubject<AuthState>(new AuthState());
  private readonly loggedIn = signal(false);

  readonly authSate$ = this._authSateSource.asObservable();

  public userTypeSig = signal<string>('');



  getAuthState(): AuthState {
    return this._authSateSource.getValue();
  }

  private _setAuthState(authState: AuthState): void {
    this._authSateSource.next(authState);
  }

  setLoginState(isLoggedIn: boolean): void {
    const state = this.getAuthState()
    state.isLoggedIn = isLoggedIn;
    this._setAuthState(state);
    this.loggedIn.update(() => isLoggedIn);
  }

  setUsernameState(userName: string): void {
    const state = this.getAuthState()
    state.userName = userName;
    this._setAuthState(state);
  }

  setDisplayNameState(userName: string): void {
    const state = this.getAuthState()
    state.displayName = userName;
    this._setAuthState(state);
  }

  getLoggedInState() {
    return this.loggedIn;
  }

  setClientState(client: ClientSettings): void {
    const state = this.getAuthState()
    state.client = client;

    if (this.userTypeSig() != client.userType) {
      this.userTypeSig.set(client.userType);
    }

    this._setAuthState(state);

    if (client?.client) {
      this.loggedIn.update(() => true);
    }
    else {
      this.loggedIn.update(() => false);
    }

    sessionStorage.setItem(
      environment.localStorage.client,
      JSON.stringify(client)
    )
    localStorage.setItem(
      environment.localStorage.client,
      JSON.stringify(client)
    )
  }

}
