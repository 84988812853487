import { ClientSettings } from './ClientSettings';


export class AuthState {
  isLoggedIn: boolean = false;

  // Based on MSAL
  userName: string | undefined;
  displayName: string | undefined;

  // Based of /Clients
  client: ClientSettings | undefined;
}
