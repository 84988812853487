import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Message, MessageService } from "primeng/api";

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  constructor(
    private messageService: MessageService,
    private readonly translateService: TranslateService) { }

  showSuccess(title: string, message: string, targetKey: string | undefined) {
    this.messageService.clear();
    this.messageService.add({ key: targetKey, severity: 'success', summary: title, detail: message });
  }

  showInfo(title: string, message: string, targetKey: string | undefined) {
    this.messageService.clear();
    this.messageService.add({ key: targetKey, severity: 'info', summary: title, detail: message });
  }

  showWarn(title: string, message: string, targetKey: string | undefined) {
    this.messageService.clear();
    this.messageService.add({ key: targetKey, severity: 'warn', summary: title, detail: message });
  }

  showError(title: string, message: string, targetKey: string | undefined) {
    this.messageService.clear();
    this.messageService.add({ key: targetKey, severity: 'error', summary: title, detail: message });
  }

  clear(targetKey: string | undefined) {
    this.messageService.clear(targetKey);
  }

  showMessage(message: Message, errorCode: string = '') {

    if (errorCode) {
      let title = this.getTranslation('errors.' + errorCode + '.title');
      let description = this.getTranslation('errors.' + errorCode + '.description');

      message.summary = title;
      message.detail = description;
    }

    this.messageService.add(message);
  }

  getTranslation(key: string): string {
    return this.translateService.instant(key) ?? '';
  }
}