import { Injectable, effect, inject } from '@angular/core';
import { AuthStoreService } from '../store/auth.store';
import { environment } from 'src/environments/environment';
import { MatomoTracker } from 'ngx-matomo-client';

@Injectable({
  providedIn: 'root'
})
export class MatomoService {

  matomoTracker = inject(MatomoTracker);
  authStoreService = inject(AuthStoreService);

  handleEffect = effect(() => {
    let userType = this.authStoreService.userTypeSig();

    if (userType) {
      this.setCustomDimension(userType);
    }
  });

  /**
   * Logs an event with an event category (Videos, Music, Games…), an event action (Play, Pause, Duration,
   * Add Playlist, Downloaded, Clicked…), and an optional event name and optional numeric value.
   *
   * @param category Category of the event.
   * @param action Action of the event.
   * @param [name] Optional name of the event.
   * @param [value] Optional value for the event.
   */
  trackEvent(category: string, action: string, name?: string): void {
    this.matomoTracker.trackEvent(category, action, name, environment.tracking.userTypeCustomDimensionId)
  }

  /**
   * Logs a visit to this page.
   *
   * @param [customTitle] Optional title of the visited page.
   */
  trackPageView(customTitle?: string): void {

    this.matomoTracker.trackPageView(customTitle);
  }
  /**
   * Set a custom dimension.<br />
   * (requires Matomo 2.15.1 + Custom Dimensions plugin)
   *
   * @param customDimensionId ID of the custom dimension to set.
   * @param customDimensionValue Value to be set.
   */
  setCustomDimension(userType: string) {
    this.matomoTracker.setCustomDimension(environment.tracking.userTypeCustomDimensionId, userType);
  }

  /**
   * Set a User ID to this user (such as an email address or a username).
   *
   * @param userId User ID to set for the current visitor.
   */
  setUserId(userId: string) {
    this.matomoTracker.setUserId(userId);
  }
}