import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpEvent } from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { Observable } from "rxjs";
import { LoadingService } from '../spinner/loading.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  service_count = 0;

  constructor(private loadingService: LoadingService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.headers.get("x-skip-loading-interceptor-handling")) {
      return next.handle(req);
    }

    this.service_count++;

    this.loadingService.show();
    return next.handle(req).pipe(
      finalize(() => {
        this.service_count--;
        if (this.service_count === 0) {
          this.loadingService.hide()
        }
      })
    );
  }
}
