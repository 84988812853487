<div class="header">
  <nav class="navbar navbar-expand-xl navbar-dark">
    <div class="container container-fluid">

      <!-- Logo -->
      <a class="navbar-brand" [routerLink]="['home']">
        <img class="logo" src="/assets/img/myarvato.svg" alt="MyArvato Logo">
      </a>

      @if(authorizationService.isLoggedIn()) {

      <!-- Menu toggle -->
      <button class="navbar-toggler m-1" type="button" data-bs-toggle="collapse" data-bs-target=".collapseableNavbar"
        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse flex-start w-100 collapseableNavbar">
        <ul class="navbar-nav">

          <!-- Navigation Items -->
          <li class="nav-item">
            <a class="nav-link" aria-current="page" [routerLink]="['home']" [routerLinkActive]="['active']">{{
              'core.header.home' | translate }}</a>
          </li>


          @if(authorizationService.hasModule(['order']) && authorizationService.hasModule(['order360'])) {
          <li class="nav-item dropdown">
            <a class="icon-link dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-current="page"
              id="orderDropdown" [ngClass]="{'active': rl1Ref.isActive || rl2Ref.isActive}" role="button">
              {{ 'core.header.order' | translate }}
              <em class="pi pi-angle-down link--icon"></em>
            </a>
            <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="orderDropdown">
              <li>
                <button class="dropdown-item p-2" [routerLink]="['order']" [routerLinkActive]="['active']"
                  #rl1Ref="routerLinkActive">{{
                  'core.header.order' | translate }}</button>
              </li>
              <li>
                <button class="dropdown-item p-2" [routerLink]="['order-360']" [routerLinkActive]="['active']"
                  #rl2Ref="routerLinkActive">{{
                  'core.header.order-360' | translate }}</button>
              </li>
            </ul>
          </li>
          }
          @else if(authorizationService.hasModule(['order'])) {
          <li class="nav-item">
            <a class="nav-link" aria-current="page" [routerLink]="['order']" [routerLinkActive]="['active']">{{
              'core.header.order' | translate }}</a>
          </li>
          }
          @else if(authorizationService.hasModule(['order360'])) {
          <li class="nav-item">
            <a class="nav-link" aria-current="page" [routerLink]="['order-360']" [routerLinkActive]="['active']">{{
              'core.header.order-360' | translate }}</a>
          </li>
          }


          @if(authorizationService.hasModule(['transportInsights']) &&
          authorizationService.hasModule(['transportTracking'])) {
          <li class="nav-item dropdown">
            <a class="icon-link dropdown-toggle nav-link" data-bs-toggle="dropdown" aria-current="page"
              id="transportDropdown" [ngClass]="{'active': rl1Ref.isActive || rl2Ref.isActive}" role="button">
              {{ 'core.header.transport' | translate }}
              <em class="pi pi-angle-down link--icon"></em>
            </a>
            <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="transportDropdown">
              <li>
                <button class="dropdown-item p-2" [routerLink]="['transport', 'insights']"
                  [routerLinkActive]="['active']" #rl1Ref="routerLinkActive">{{
                  'core.header.transport-insights' | translate }}</button>
              </li>
              <li>
                <button class="dropdown-item p-2" [routerLink]="['transport', 'tracking']"
                  [routerLinkActive]="['active']" #rl2Ref="routerLinkActive">{{
                  'core.header.transport-tracking' | translate }}</button>
              </li>
            </ul>
          </li>
          }
          @else if(authorizationService.hasModule(['transportInsights'])) {
          <li class="nav-item">
            <a class="nav-link" aria-current="page" [routerLink]="['transport', 'insights']"
              [routerLinkActive]="['active']">{{
              'core.header.transport' | translate }}</a>
          </li>
          }
          @else if(authorizationService.hasModule(['transportTracking'])) {
          <li class="nav-item">
            <a class="nav-link" aria-current="page" [routerLink]="['transport', 'tracking']"
              [routerLinkActive]="['active']">{{
              'core.header.transport' | translate }}</a>
          </li>
          }

          @if(authorizationService.hasModule(['report'])) {
          <li class="nav-item">
            <a class="nav-link" aria-current="page" [routerLink]="['reports']" [routerLinkActive]="['active']">{{
              'core.header.report' | translate }}</a>
          </li>
          }

          @if(authorizationService.hasModule(['cxc'])) {
          <li class="nav-item dropdown">
            <a class="icon-link dropdown-toggle nav-link " data-bs-toggle="dropdown" aria-current="page"
              id="userDropdown" role="button"
              [ngClass]="{'active': rl1Ref.isActive || rl2Ref.isActive || rl3Ref.isActive || rl4Ref.isActive}">
              {{ 'core.header.cxc' | translate }}
              <em class="pi pi-angle-down link--icon"></em>
            </a>
            <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="userDropdown">
              <li [ngClass]="{'d-none': !authorizationService.hasModule(['cxc'])}">
                <button class="dropdown-item p-2" [routerLink]="['cxc', 'shipments']" [routerLinkActive]="['active']"
                  #rl1Ref="routerLinkActive">
                  {{ 'core.header.cxc-shipment' | translate }}</button>
              </li>
              <li [ngClass]="{'d-none': !authorizationService.hasModule(['cxcAnalyticsTc'])}">
                <button class="dropdown-item p-2" [routerLink]="['cxc', 'insights', 'tc']"
                  [routerLinkActive]="['active']" #rl2Ref="routerLinkActive">
                  {{ 'core.header.cxc-tc' | translate }}</button>
              </li>
              <li [ngClass]="{'d-none': !authorizationService.hasModule(['cxcAnalyticsOrc'])}">
                <button class="dropdown-item p-2" [routerLink]="['cxc', 'insights', 'orc']"
                  [routerLinkActive]="['active']" #rl3Ref="routerLinkActive">
                  {{ 'core.header.cxc-orc' | translate }}</button>
              </li>
              <li [ngClass]="{'d-none': !authorizationService.hasModule(['cxcEdd'])}">
                <button class="dropdown-item p-2" [routerLink]="['cxc', 'edd']" [routerLinkActive]="['active']"
                  #rl4Ref="routerLinkActive">
                  {{ 'core.header.cxc-edd' | translate }}</button>
              </li>
            </ul>
          </li>
          }

        </ul>
      </div>

      <!-- Navigation Items -->
      <div class="collapse navbar-collapse flex-end collapseableNavbar">
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="icon-link dropdown-toggle" [ngClass]="languages.length > 0 ? 'disabled' : ''"
              data-bs-toggle="dropdown" aria-current="page" id="languageDropdown" role="button">
              {{ selectedLanguage().language }}
              <em class="pi pi-angle-down  link--icon"></em>
            </a>
            <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="clientDropdown">
              @for (item of languages(); track $index) {
              <li class="p-2 mb-0 dropdown-item">
                <button (click)="setLanguage(item.code)" class="dropdown-item">{{item.language}}</button>
              </li>
              }
            </ul>
          </li>

          <li class="nav-item dropdown">

            @if(hasMultipleClients){
            <a class="icon-link dropdown-toggle" data-bs-toggle="dropdown" aria-current="page" id="clientDropdown"
              role="button">
              {{client?.client}}
              <em class="pi pi-angle-down link--icon"></em>

            </a>
            <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="clientDropdown">
              @for (item of clients; track $index) {
              @if(item.client !== client?.client) {
              <li class="p-2 mb-0 dropdown-item" (click)="switchClient(item)">
                {{item.client}}
              </li>
              }
              }
            </ul>
            }
            @else if(hasOneClients) {
            <span class="one-client" aria-current="page" role="button">
              {{client?.client}}
            </span>
            }
          </li>


          <li class="nav-item dropdown">
            <a class="icon-link dropdown-toggle" data-bs-toggle="dropdown" aria-current="page" id="userDropdown"
              role="button">
              {{userName}}
              <em class="pi pi-angle-down link--icon"></em>
            </a>

            <ul class="dropdown-menu dropdown-menu-end p-0" aria-labelledby="userDropdown">
              <li>
                <button class="dropdown-item p-2" [routerLink]="['setting']"><em class="pi pi-cog me-1"></em>{{
                  'core.header.settings' | translate }}</button>
              </li>
              <li>
                <button class="dropdown-item p-2" [routerLink]="['logout'] ">
                  <em class="pi pi-sign-out me-1"></em> {{ 'core.header.logout' | translate }}</button>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      }
    </div>
  </nav>
</div>
