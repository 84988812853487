import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from "./core/error/error.component";
import { LoginComponent } from './modules/login/pages/login/login.component';
import { LogoutComponent } from './core/logout/logout.component';
import { PrivacyComponent } from './modules/legal/pages/privacy/privacy.component';
import { UnauthorizedComponent } from './core/unauthorized/unauthorized.component';
import { ModuleCard } from './modules/home/moduleCard';
import { ModuleGuard } from './core/guards/module.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth'
  },
  {
    path: 'auth',
    component: LoginComponent,
    loadChildren: () => import('./modules/login/login-routing.module').then(m => m.LoginRoutingModule),
    title: "MyArvato - Login"
  },
  {
    path: 'logout',
    component: LogoutComponent,
    title: "MyArvato - Logout"
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home-routing.module').then(m => m.HomeRoutingModule),
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    data: {
      breadcrumb: 'breadcrumbs.privacy-policy',
    }
  },
  {
    path: 'cxc',
    loadChildren: () => import('./modules/cxc/cxc-routing.module').then(m => m.CxcRoutingModule),
    canActivate: [ModuleGuard],
    data: {
      module: ['cxc'],
      breadcrumb: 'breadcrumbs.cxc.main'
    }
  },
  {
    path: 'order',
    loadChildren: () => import('./modules/order/order.routing.module').then(m => m.OrderRoutingModule),
    canActivate: [ModuleGuard],
    data: {
      module: ['order'],
      breadcrumb: 'breadcrumbs.order.main'
    }
  },
  {
    path: 'order-360',
    loadChildren: () => import('./modules/order-360/order-360.routing.module').then(m => m.Order360RoutingModule),
    canActivate: [ModuleGuard],
    data: {
      module: ['order360'],
      breadcrumb: 'breadcrumbs.order-360.main'
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('./modules/reports/reports-routing.module').then(m => m.ReportsRoutingModule),
    canActivate: [ModuleGuard],
    data: {
      module: ['report'],
      breadcrumb: 'breadcrumbs.report.main'
    }
  },
  {
    path: 'transport',
    loadChildren: () => import('./modules/transport/transport-routing.module').then(m => m.TransportRoutingModule),
    canActivate: [ModuleGuard],
    data: {
      module: ['transport', 'transportTracking', 'transportInsights'],
      breadcrumb: 'breadcrumbs.transport.main'
    }
  },
  {
    path: 'setting',
    loadChildren: () => import('./modules/setting/setting-routing.module').then(m => m.AdminRoutingModule),
    data: {
      breadcrumb: 'breadcrumbs.setting.main'
    }
  },
  {
    path: 'error/:errorCode/:traceId',
    component: ErrorComponent,
    data: {
      breadcrumb: 'breadcrumbs.something-went-wrong'
    }
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
    data: {
      breadcrumb: 'breadcrumbs.unauthorized-user'
    }
  },
  {
    path: 'error/:errorCode',
    redirectTo: 'error/:errorCode/'
  },
  {
    path: '**',
    redirectTo: 'error/F0404/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
