<div class="bg-image" [ngClass]="backgoundImageClass">
  <div class="container h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-md-6 col-lg-6 col-xl-5 mb-4 mt-4">
        <div class="card">

          <div class="card-body p-4">
            <h4>
              <p>{{ 'pages.login.welcome' | translate }}</p>
            </h4>
            <h6 class="mb-5">
              {{ 'pages.login.text-1' | translate }}
            </h6>

            <p class="mt-5">
              {{ 'pages.login.login' | translate }}
            </p>

            <arvato-button button class="w-100" state="secondary" (click)="authService.login()">
              <i class="bi bi-windows mr-5"></i>
              {{ 'pages.login.login-button' | translate }}
            </arvato-button>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>