<app-title [useBreadcrumbs]="customBreadcrumb" [title]="title" [text]="description" [darkMode]="false" [big]="true">
  <div *ngIf="content">
    <br />
    <span>{{ content }}</span>
  </div>
</app-title>

<div class="container container--xl mt-3 mb-3">
  <div *ngIf="traceId">
    <br /><br />
    <span class="trace--id">TraceID: {{ traceId }}</span>
  </div>

  <h5 class="mt-5 mb-4">{{ 'pages.error.help-text' | translate }}</h5>

  <app-contact>
    <app-contact-card name="Julia Bauch" position="Digital Product Manager" image="assets/img/contact/julia_bauch.jpg"
      email="julia.bauch@arvato-scs.com"></app-contact-card>
    <app-contact-card name="Bhagyashri Khole" position="Digital Product Manager"
      image="assets/img/contact/bhagyashri_khole.jpg" email="bhagyashri.khole@arvato-scs.com"></app-contact-card>
  </app-contact>
</div>