import { Inject, Injectable, inject } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthStoreService } from '../store/auth.store';
import { AccountInfo, AuthenticationResult, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { MatomoService } from '../matomo/matomo-service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(

    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private authStoreService: AuthStoreService,
    private matomoService: MatomoService) {

    this.msalService.handleRedirectObservable().subscribe();

    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
  }

  router = inject(Router);
  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      // add your code for handling multiple accounts here
      this.msalService.instance.setActiveAccount(accounts[0]);
    }

    // Update Store
    if (this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();

      if (!this.hasUserGroups(accounts[0])) {
        this.router.navigate(['unauthorized'])
        return;
      }
      this.authStoreService.setLoginState(true)
      this.authStoreService.setUsernameState(accounts[0].username.toLowerCase());
      this.authStoreService.setDisplayNameState(accounts[0].name ?? '');

      if (environment.tracking.addUserInformation) {
        this.matomoService.setUserId(accounts[0].username.toLowerCase())
      }

    }
    else {
      this.authStoreService.setLoginState(false)
      this.authStoreService.setUsernameState('')
      this.authStoreService.setDisplayNameState('');

      if (environment.tracking.addUserInformation) {
        this.matomoService.setUserId('')
      }
    }
  }


  /**
   * Checks whether the user has group claims
   * @param account accountInfo
   * @returns
   */
  hasUserGroups(account: AccountInfo): boolean {

    let tokenClaims = account.idTokenClaims as any
    let groups = tokenClaims.groups as string[];

    if (groups) {
      return groups.length > 0
    }

    return false;
  }

  /**
   * Perform a user login using MSAL
   * @param userFlowRequest
   */
  login(userFlowRequest?: RedirectRequest | PopupRequest) {


    this.msalService.instance.handleRedirectPromise();

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.msalService.loginRedirect(userFlowRequest);
      }
    }
  }

  /**
   * Perform a user logout using MSAL
   */
  logout() {
    this.msalService.instance.handleRedirectPromise();

    this.msalService.logout();
  }

  forceLogout() {
    localStorage.removeItem(environment.localStorage.client)
    sessionStorage.removeItem(environment.localStorage.client)
    sessionStorage.removeItem('brand')
  }
}
