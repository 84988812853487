import { Component } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';
import { AuthService } from './core/auth/auth.service';
import { Router, RouterOutlet } from '@angular/router';
import { AuthStoreService } from './core/store/auth.store';
import { ToastModule } from 'primeng/toast';
import { FooterComponent } from './core/footer/footer.component';
import { HeaderComponent } from './core/header/header.component';
import { SpinnerComponent } from './core/spinner/spinner.component';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [ToastModule, FooterComponent, RouterOutlet, HeaderComponent, SpinnerComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'myArvato - Portal';
  private readonly _destroying$ = new Subject<void>();

  private subscription: Subscription | undefined;
  private localSubscription: Subscription | undefined;

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private authService: AuthService,
    private authStoreService: AuthStoreService,
    private router: Router
  ) {
  }

  ngOnInit(): void {

    this.subscription = this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.authService.checkAndSetActiveAccount()
        // Do user account/UI functions here
        this.authStoreService.authSate$
          .subscribe(authStore => {

            // Redirect to home if login was successfull
            if (this.router.url.endsWith('/auth') && authStore.isLoggedIn) {
              this.router.navigate(['home']);
            }
          })

      })
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.localSubscription) {
      this.localSubscription.unsubscribe();
    }
  }
}
